<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            label="대상년도"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            :plantCd="searchParam.plantCd"
            type="search" 
            :isFirstValue="false" 
            label="LBLDEPT" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <c-checkbox
            :editable="editable"
            trueLabel="O"
            falseLabel="전체"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="실시대상"
            name="checkupFlags"
            v-model="searchParam.checkupFlags"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <c-checkbox
            :editable="editable"
            trueLabel="O"
            falseLabel="전체"
            :comboItems="flagConfirmItems"
            itemText="codeName"
            itemValue="code"
            label="실시여부"
            name="checkupConfirmFlags"
            v-model="searchParam.checkupConfirmFlags"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="건강검진 실시여부 목록"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="userId"
      :columnSetting="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-implementation',
  data() {
    return {
      flagItems: [
        // { code: 'normalFlag', codeName: '일반검진' },
        { code: 'specialFlag', codeName: '특수검진' },
        { code: 'batchMoveFlag', codeName: '배치검진여부' },
      ],
      flagConfirmItems: [
        { code: 'normalConfirmFlag', codeName: '일반검진' },
        { code: 'specialConfirmFlag', codeName: '특수검진' },
        { code: 'batchBeforeConfirmFlag', codeName: '배치전검진' },
        { code: 'batchAfterConfirmFlag', codeName: '배치후검진' },
      ],
      grid: {
        merge: [
          // { index: 1, colName: 'deptㅜ' },
          // { index: 2, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          // {
          //   name: 'upDeptName',
          //   field: 'upDeptName',
          //   style: 'width: 100px',
          //   label: '상위소속',
          //   align: 'center',
          //   sortable: true,
          //   fix: true,
          // },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '성명',
            align: 'center',
            sortable: true,
            fix: true,
          },
          // {
          //   name: 'spotName',
          //   field: 'spotName',
          //   style: 'width: 80px',
          //   label: '직위',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'jobName',
          //   field: 'jobName',
          //   style: 'width: 80px',
          //   label: '직책',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'birthDate',
          //   field: 'birthDate',
          //   style: 'width: 100px',
          //   label: '생년월일',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'age',
          //   field: 'age',
          //   style: 'width: 60px',
          //   label: '연령',
          //   align: 'right',
          //   sortable: true,
          // },
          // {
          //   name: 'enterDate',
          //   field: 'enterDate',
          //   label: '입사일',
          //   align: 'center',
          //   style: 'width: 100px',
          //   sortable: false,
          // },
          // {
          //   name: 'longevityCount',
          //   field: 'longevityCount',
          //   label: '근속년수',
          //   align: 'right',
          //   style: 'width: 60px',
          //   sortable: false,
          // },
          {
            label: '건강검진 실시대상',
            align: 'center',
            child: [
              {
                name: 'normalFlag',
                field: 'normalFlag',
                label: '일반검진',
                style: 'width: 80px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'specialFlag',
                field: 'specialFlag',
                label: '특수검진',
                style: 'width: 80px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'batchMoveFlag',
                field: 'batchMoveFlag',
                label: '배치검진여부',
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
                value: 'N',
                align: 'center',
                sortable: true,
              },
              // {
              //   name: 'batchBefFlag',
              //   field: 'batchBefFlag',
              //   label: '배치전검진',
              //   style: 'width:80px',
              //   align: 'center',
              //   sortable: true,
              // },
              // {
              //   name: 'batchAftFlag',
              //   field: 'batchAftFlag',
              //   label: '배치후검진',
              //   style: 'width:80px',
              //   align: 'center',
              //   sortable: true,
              // },
            ]
          },
          {
            label: '건강검진 실시여부',
            align: 'center',
            headerColor: '#b0d9fb',
            child: [
              {
                name: 'normalConfirmFlag',
                field: 'normalConfirmFlag',
                label: '일반검진',
                style: 'width: 80px',
                headerColor: '#b0d9fb',
                align: 'center',
                sortable: true,
              },
              {
                name: 'specialConfirmFlag',
                field: 'specialConfirmFlag',
                label: '특수검진',
                style: 'width: 80px',
                headerColor: '#b0d9fb',
                align: 'center',
                sortable: true,
              },
              {
                name: 'batchBeforeConfirmFlag',
                field: 'batchBeforeConfirmFlag',
                label: '배치전검진',
                style: 'width:80px',
                headerColor: '#b0d9fb',
                align: 'center',
                sortable: true,
              },
              {
                name: 'batchAfterConfirmFlag',
                field: 'batchAfterConfirmFlag',
                label: '배치후검진',
                style: 'width:80px',
                headerColor: '#b0d9fb',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width: 250px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        userId: '',
        year: '',
        checkupFlags: [],
        checkupConfirmFlags: [],
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.implementation.list.url;
      this.saveUrl = transactionConfig.hea.checkup.implementation.save.url;
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '대상년도를 선택하세요.',
          type: 'error', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
